// Auto generated constants
export default {OFFICE_SPACE_ID_LENGTH: 4,
RELATIVE_ROOM_ID_LENGTH: 4,
MIN_TENANT_NAME_LENGTH: 3,
MAX_TENANT_NAME_LENGTH: 64,
MIN_OFFICE_SPACE_NAME_LENGTH: 3,
MAX_OFFICE_SPACE_NAME_LENGTH: 32,
MIN_ROOM_NAME_LENGTH: 3,
MAX_ROOM_NAME_LENGTH: 32,
AVATAR_SIZE_PX: 48,
ENABLE_NEXT_DAY_AFTER: 23,
ENABLE_PREVIOUS_DAY_BEFORE: 1,
WEBSOCKET_MAX_CONNECTION_DURATION_MINUTES: 120,
WEBSOCKET_IDLE_CONNECTION_TIMEOUT_MINUTES: 10,
WEBSOCKET_REFRESH_BUFFER_SECONDS: 300,
COST_UPDATE_INTERVAL_MS: 10000,
FORM_CONSTANTS:{
    MIN_NAME_LENGTH: 3,
    MAX_NAME_LENGTH: 48,
    MAX_EMAIL_ADDRESS_LENGTH: 128,
    MIN_MESSAGE_LENGTH_IF_REQUIRED: 5,
    MAX_MESSAGE_LENGTH: 2000,
    LAWS: ["GDPR", "CCPA", "CPA", "CTDPA", "UCPA", "VCDPA", "OTHER"],
    PURPOSES: ["knowWhatInfoIsCollected", "deleteMyInfo", "optOutSellToThirdParties", "optInSellMyData", "accessMyInfo", "correctInfo", "receiveACopyOfMyInfo", "optOutCrossContextAdvertising", "limitTheUseOfMyInfo", "otherPleaseSpecifyInTheCommentBoxBelow"],
    CONFIRMATIONS: ["confirmInformationIsCorrect", "confirmUnderstandDeletionIsIrreversible", "confirmUnderstandValidationRequired"]},
SUBSCRIPTIONS: [{"id": 0, "name": "Freemium", "price": {"yearly": 0, "monthly": 0}}, {"id": 1, "name": "Business", "price": {"yearly": 4, "monthly": 6}}, {"id": 2, "name": "Enterprise", "price": {"yearly": 7, "monthly": 9}}],
FEATURE_MATRIX:{
    sso:{
    Freemium: true,
    Business: true,
    Enterprise: true},
    liveMeetingCostCalculator:{
    Freemium: true,
    Business: true,
    Enterprise: true},
    maxNumUsers:{
    Freemium: 50,
    Business: 100,
    Enterprise: "unlimited"},
    maxOfficeSpaces:{
    Freemium: 2,
    Business: 5,
    Enterprise: "unlimited"},
    maxRoomsPerOfficeSpace:{
    Freemium: 5,
    Business: 10,
    Enterprise: "unlimited"},
    maxPrivateRoomsPerOfficeSpace:{
    Freemium: 1,
    Business: 3,
    Enterprise: "unlimited"},
    crossCompanyCommunication:{
    Freemium: false,
    Business: false,
    Enterprise: true},
    multiPlatform:{
    Freemium: false,
    Business: false,
    Enterprise: true}},
PAYMENT_LINKS:{
    Business:{
    yearly: "https://buy.stripe.com/test_5kA7vU9cH3Vt0wgeVf",
    monthly: "https://buy.stripe.com/test_cN25nM4Wr8bJ4Mw3cy"},
    Enterprise:{
    yearly: "https://buy.stripe.com/test_fZe2bAagL77F2Eo5kH",
    monthly: "https://buy.stripe.com/test_14k2bA2OjbnV3IseVi"}},
APP_STORE_LINKS:{
    msteams: "https://teams.microsoft.com/l/app/",
    zoom: "https://marketplace.zoom.us/apps/"},
MS_TEAMS_CLIENT_NAME: "msteams",
ZOOM_CLIENT_NAME: "zoom",
HYBROOMS_NATIVE_CLIENT_NAME: "hybRoomsNative",
MULTI_CLIENT_NAME: "multiClient",
PUBLIC_CLIENT_NAME: "public",
CROSS_CLIENT_NAME: "crossClient",
CLIENT_IDS:{
    msteams:{
    production: "c574950b-c02d-40d4-afe0-bda266c5e037",
    development: "1ff7813e-f619-461f-8a42-510bac8b0fae"},
    zoom:{
    production: "MSlpXoj3T4yKFL2XiZQQ",
    development: "HsbDYj51QaerRVlS9bi_0A"},
    public:{
    production: "public_hybrooms",
    development: "public_hybrooms_dev"}},
REDIRECT_URIS:{
    msteams:{
    production: "https://www.hybrooms.com/#/init",
    development: "https://prime-secure-minnow.ngrok-free.app/#/init"},
    zoom:{
    production: "https://www.hybrooms.com/#/init",
    development: "https://prime-secure-minnow.ngrok-free.app/#/init"}},
APP_URIS:{
    production: "https://www.hybrooms.com",
    development: "https://prime-secure-minnow.ngrok-free.app"},
APP_ID_URIS:{
    production: "api://www.hybrooms.com/c574950b-c02d-40d4-afe0-bda266c5e037",
    development: "api://prime-secure-minnow.ngrok-free.app/1ff7813e-f619-461f-8a42-510bac8b0fae"},
STRIPE_PUBLISHABLE_KEYS:{
    production: "pk_live_51OrPkhGR6rFd14wnAtwJC4cKsmviiX15UI5JqYaF5IAQPTYVCXSfke7FPzHUUaP0Y1foj4KjPaowXdH2DdelBYd3008YDMJAhJ",
    development: "pk_test_51OrPkhGR6rFd14wnHMFBtV6Hf1kkjEbKT0fRYuFHIsIbu5POwnTUpUFZS5T12D08ftNUk9BosF7oeG0cAU4ef4L600idcgSKyP"}}