import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './vue-i18n'
import './lib/cookieHandler'
import { app } from "@microsoft/teams-js";

import constants from './constants_gen';
Vue.prototype.$CONSTANTS = constants;

import Confirm from "@/components/Confirm.vue";
import Loading from "@/components/Loading.vue";

// require('@/assets/main.scss');

import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);
// Vue.component('font-awesome-layers', FontAwesomeLayers);
// Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

function openLink(url: string) {
  if (store.state.hybRoomsClient == constants.MS_TEAMS_CLIENT_NAME && !store.state.microsoft365) {
    app.openLink(url);
  }
  else {
    window.open(url);
  }
}

Vue.prototype.$openLink = async function (url: string) {
  if (store.state.hybRoomsClient != constants.MS_TEAMS_CLIENT_NAME || (store.state.hybRoomsClient == constants.MS_TEAMS_CLIENT_NAME && url.startsWith("https://teams.microsoft.com/l/"))) {
    openLink(url);
    return;
  }

  const props = {
    parent: this,
    component: Confirm,
    hasModalCard: true,
    trapFocus: true,
    fullScreen: false,
    canCancel: false,
    props: {
      title: this.$t("openExternalPageInBrowser"),
      text: this.$t("sentences.thisWillOpenExternalPageInYourBrowser"),
      confirmButtonText: this.$t("interaction.viewIn", { hostname: new URL(url).hostname }),
      cancelButtonText: this.$t("interaction.cancel"),
      type: "is-primary",
      confirmButtonRightIcon: "external-link-alt"
    },
    events: {
      'confirm': (confirmed: boolean) => {
        confirmed ? openLink(url) : {};
      }
    }
  };
  this.$buefy.modal.open(props);
}

Vue.prototype.$hashString = function (str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char; // Equivalent to: hash * 31 + char
  }
  return hash;
}


Vue.prototype.$showLoading = function (title = "", subtitle = "", subtitleDelay = 0) {
  const hash = Vue.prototype.$hashString([title, subtitle, String(subtitleDelay)].join("@@@@@"));
  if (hash in store.state.loadingComponents)
    store.state.loadingComponents[hash].counter += 1;
  else {
    store.state.loadingComponents[hash] = {
      counter: 1,
      component: this.$buefy.modal.open({
        parent: this,
        component: Loading,
        hasModalCard: false,
        trapFocus: true,
        fullScreen: false,
        canCancel: true,
        props: {
          title: title,
          subtitle: subtitle,
          subtitleDelay: subtitleDelay
        }
      })
    };
  }
  return hash;
}


Vue.prototype.$closeLoading = function (hash = 0) {
  if (hash == 0) {
    hash = Vue.prototype.$hashString(["", "", String(0)].join("@@@@@"));
  }

  if (!(hash in store.state.loadingComponents))
    return;

  store.state.loadingComponents[hash].counter -= 1;

  if (store.state.loadingComponents[hash].counter <= 0) {
    store.state.loadingComponents[hash].component.close();
    delete store.state.loadingComponents[hash];
  }
}


Vue.prototype.$setDarkMode = function (value: boolean) {
  const id = "dynamicDarkModeCss";
  const el = document.getElementById(id);
  if (value && el) {
    store.state.darkMode = true;
    return;
  }
  if (value) {
    const file = document.createElement("link");
    file.id = id;
    file.rel = "stylesheet";
    file.href = "/css/style_dark.css";
    document.head.appendChild(file);
  } else {
    if (el)
      el.remove();
  }
  store.state.darkMode = value;
},


  Vue.prototype.$getInitUrl = function (tenantId = "", officeSpaceId = "", roomId = "") {
    const baseUrl = constants.APP_URIS[process.env.NODE_ENV as keyof typeof constants.APP_URIS];
    let url = `${baseUrl}/#/init`;
    if (tenantId) {
      url += `?redirectTenant=${tenantId}`;
      if (officeSpaceId)
        url += `&redirectOffice=${officeSpaceId}`;
      if (roomId)
        url += `&joinRoom=${roomId}`;
    }
    return url;
  }

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
