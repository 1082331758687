<template>
    <div>
        <!--b-field>
            <div class="is-flex is-justify-content-space-between">
                <b-field grouped expanded :style="isEnterprisePlan ? '' : 'opacity: 0.5;'">
                    <b-field>
                        <b-icon icon="lock" size="is-small"></b-icon>
                    </b-field>
                    <b-field>
                        <b>{{ $t("private") }}</b>
                    </b-field>
                    <b-field v-if="!isEnterprisePlan">
                        <b-tag type="is-info">ENTERPRISE</b-tag>
                    </b-field>
                </b-field>
                <b-switch v-model="room.isPrivate" :disabled="!isEnterprisePlan"></b-switch>
            </div>
        </b-field-->
        <!--b-field>
            <div class="is-flex is-justify-content-space-between" expanded>
                <b-field grouped>
                    <b-field>
                        <b-icon icon="video" size="is-small"></b-icon>
                    </b-field>d
                    <b-field>
                        <b>{{ $t("enableVideo") }}</b>
                    </b-field>
                </b-field>
                <b-switch v-model="room.video" :disabled="!(room.audio || room.chat)"></b-switch>
            </div>
        </b-field>
        <b-field>
            <div class="is-flex is-justify-content-space-between" expanded>
                <b-field grouped>
                    <b-field>
                        <b-icon icon="microphone" size="is-small"></b-icon>
                    </b-field>
                    <b-field>
                        <b>{{ $t("enableAudio") }}</b>
                    </b-field>
                </b-field>
                <b-switch v-model="room.audio" :disabled="!(room.video || room.chat)"></b-switch>
            </div>
        </b-field-->
        <b-field>
            <div class="is-flex is-justify-content-space-between" expanded>
                <b-field grouped>
                    <b-field>
                        <b-icon icon="comment" size="is-small"></b-icon>
                    </b-field>
                    <b-field>
                        <b>{{ $t("enableChat") }}</b>
                    </b-field>
                </b-field>
                <b-switch v-model="room.chat" :disabled="!(room.audio || room.video)"></b-switch>
            </div>
        </b-field>
    </div>
</template>

<script>
export default {
    props: {
        roomData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            room: this.roomData
        }
    },
    computed: {
        isEnterprisePlan() {
            return this.$store.state.subscriptionPlan == "enterprise";
        }
    }
}
</script>