<template>
    <div id="app">
        <demo-cursor v-if="$store.state.demoMode.showDemoCursor.active" />
        <div class=" is-fullheight">
            <navbar id="nav" v-if="$route.name && showNavBar" :isHomePage="isHomePage"
                v-on:navigateToOfficeSpace="navigateToOfficeSpace" />
            <router-view id="router" ref="router" v-on:createOfficeSpace="openCreateOfficeSpaceModal"
                v-on:createRoom="openCreateRoomModal" v-on:joinMeeting="joinMeeting"
                v-on:createAdHocMeeting="createAdHocMeeting" />
        </div>
        <!--nav class="level">
        <p class="level-item has-text-centered">
            <b-navbar-item tag="router-link" :to="{ path: '/about' }">{{ $t('aboutHybrooms') }}</b-navbar-item>
        </p>
    </nav-->
    </div>
</template>

<script>
import { app } from "@microsoft/teams-js";
import zoomSdk from "@zoom/appssdk";

import { hybRoomsConnector } from '@/lib/connector';

import OfficeSpaceSettingsModal from '@/components/OfficeSpaceSettingsModal.vue';
import RoomSettingsModal from '@/components/RoomSettingsModal.vue';
import NavBar from '@/components/NavBar.vue';

import OfficeSpace from '@/types/generated/OfficeSpace.js';

function Room(client, tenantId, officeSpaceId, adHoc) {
    return {
        client: client,
        tid: tenantId,
        officeSpaceId: officeSpaceId,
        id: crypto.randomUUID(),
        name: "",
        adHoc: adHoc,
        video: true,
        audio: true,
        chat: true,
        doNotDisturb: false,
        isPrivate: false,
    }
}

export default {
    components: {
        "navbar": NavBar,
        "demo-cursor": () => {
            if (process.env.NODE_ENV === "development") {
                return import("@/components/DemoCursor.vue");
            }
            return null;
        }
    },
    computed: {
        isOfficeSpaceView() {
            return this.$route.name == "officeSpace";
        },

        isAnalyticsView() {
            return this.$route.name == "analytics";
        },

        isAdminCenterView() {
            return this.$route.name == "admincenter";
        },

        isInitView() {
            return this.$route.name == "init";
        },

        isHomePage() {
            return !(this.isOfficeSpaceView || this.isAnalyticsView || this.isAdminCenterView) || this.$store.state.hybRoomsClient == this.$CONSTANTS.PUBLIC_CLIENT_NAME;
        },

        showNavBar() {
            if (this.$store.state.initializingClient)
                return false;
            if (this.$store.state.hybRoomsClient == this.$CONSTANTS.MS_TEAMS_CLIENT_NAME || this.$store.state.hybRoomsClient == this.$CONSTANTS.ZOOM_CLIENT_NAME)
                return !this.isInitView;
            return true;
        },

        viewTenantId() {
            return this.$store.state.viewTenantId;
        },

        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },
    },
    mounted() {
        document.title = "HybRooms";
        this.detectOS();
        this.detectHybRoomsClient();
        this.configureDemoMode({
            enable: false,
            showDemoCursor: false,
            mockOnlineMeeting: false,
            showDummyParticipants: false,
            overrideCompanyName: false,
            overrideDisplayName: false,
            overrideDateTime: false,
            overrideCostUpdateInterval: false,
            mockAnalytics: false
        });

        // alert(sessionStorage.getItem("test"));
        // sessionStorage.setItem("test", crypto.randomUUID());
        this.$store.state.systemDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
        this.$setDarkMode(this.$store.state.systemDarkMode);

        this.$nextTick(() => {
            this.onResize();
            window.addEventListener('resize', this.onResize);
        })

        if (this.$router.currentRoute.path.startsWith("/access_token=")) {
            const pairs = this.$router.currentRoute.path.substring(1).split("&");
            let query = {};
            for (const p of pairs) {
                const kv = p.split('=');
                query[kv[0]] = kv[1];
            }
            if (query.token_type == "Bearer" && query.state.startsWith("checkout.")) {
                const [_, client, plan, period] = query.state.split(".");
                if (client != this.$CONSTANTS.MS_TEAMS_CLIENT_NAME || !["business", "enterprise"].includes(plan) || !["yearly", "monthly"].includes(period))
                    return;
                this.$router.push({ path: `checkout/${client}/${plan}/${period}`, query: query });
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        detectOS() {
            let os = window.navigator.platform.toLowerCase();
            if (os.startsWith("win"))
                os = "windows";
            else if (os.startsWith("mac"))
                os = "mac";
            else if (os.startsWith("linux"))
                os = "linux";
            else if (os.startsWith("iphone"))
                os = "iphone";
            this.$store.state.os = os;
        },
        detectHybRoomsClient() {
            this.initMSTeams().catch(() => this.initZoomClient().catch(() => this.showCookieBanner()));
        },
        setHybRoomsClient(client) {
            this.$store.state.hybRoomsClient = client;
        },
        configureDemoMode(config) {
            const activeSwitch = process.env.NODE_ENV != "development" ? false : config.enable;
            this.$store.state.demoMode.active = activeSwitch;
            this.$store.state.demoMode.showDemoCursor.active = activeSwitch && config.showDemoCursor;
            this.$store.state.demoMode.mockOnlineMeeting.active = activeSwitch && config.mockOnlineMeeting;
            this.$store.state.demoMode.showDummyParticipants.active = activeSwitch && config.showDummyParticipants;
            this.$store.state.demoMode.companyNameOverride.active = activeSwitch && config.overrideCompanyName;
            this.$store.state.demoMode.displayNameOverride.active = activeSwitch && config.overrideDisplayName;
            this.$store.state.demoMode.dateTimeOverride.active = activeSwitch && config.overrideDateTime;
            this.$store.state.demoMode.costUpdateIntervalOverride.active = activeSwitch && config.overrideCostUpdateInterval;
            this.$store.state.demoMode.mockAnalytics.active = activeSwitch && config.mockAnalytics;
        },
        async initMSTeams() {
            try {
                await app.initialize().then(() => this.setHybRoomsClient(this.$CONSTANTS.MS_TEAMS_CLIENT_NAME));
            } catch (e) {
                throw "TeamsFailed";
            }
        },
        async initZoomClient() {
            try {
                await zoomSdk.config({
                    size: { width: 480, height: 360 },
                    capabilities: ["getRunningContext"]
                }).then(() => this.setHybRoomsClient(this.$CONSTANTS.ZOOM_CLIENT_NAME));
            }
            catch {
                throw "ZoomFailed";
            }
        },
        showCookieBanner() {
            if (this.$getCookie("cookieConsented")) {
                this.saveCookieConsent();
                return;
            }
            this.$buefy.snackbar.open({
                message: this.$t("sentences.hybRoomsUsesOnlyTechnicallyEssentialCookies"),
                indefinite: true,
                type: 'is-success',
                position: 'is-bottom',
                actionText: this.$t("interaction.OK"),
                onAction: this.saveCookieConsent
            })
        },
        saveCookieConsent() {
            this.$setCookie("cookieConsented", true, 365)
        },
        // authZoom() {
        //     // https://appssdk.zoom.us/classes/ZoomSdk.ZoomSdk.html#promptAuthorize
        //     zoomSdk.promptAuthorize()
        //         .then((res) => alert(res))
        //         .catch((err) => alert(err))
        //     zoomSdk.addEventListener("onAuthorized", (event) => {
        //         const { code, state } = event;
        //         alert(code);
        //     });
        //     zoomSdk.authorize({ codeChallenge: "lkjqwp4rt9uq03wr" })
        //         .then((ret) => {
        //             if (ret.message.toLowerCase() == "success" && window.location.href.endsWith("/#/zoominit")) {
        //                 // window.location.replace("https://zoom.us/oauth/authorize?client_id=HsbDYj51QaerRVlS9bi_0A&response_type=code&redirect_uri=https%3A%2F%2Fprime-secure-minnow.ngrok-free.app%2F%23%2Fzoomlogin");
        //             }
        //         })
        //         .catch((e) => {
        //             this.ret = e;
        //             alert(e);
        //         });
        // },
        joinMeeting(url) {
            if (url.length == 0)
                return;
            try {
                if (this.$store.state.hybRoomsClient == this.$CONSTANTS.MS_TEAMS_CLIENT_NAME && this.$store.state.clientType != "web")
                    this.$openLink(url);
                else if (this.$store.state.hybRoomsClient == this.$CONSTANTS.ZOOM_CLIENT_NAME) {
                    const options = { joinURL: url, newHost: "" };
                    zoomSdk.joinMeeting(options);
                }
                else
                    throw new Error();
            } catch {
                window.open(url, "blank");
            }
        },

        onResize() {
            this.$store.state.windowWidth = window.innerWidth;
            this.$store.state.windowHeight = window.innerHeight;
        },

        openCreateOfficeSpaceModal() {
            this.$buefy.modal.open({
                parent: this,
                component: OfficeSpaceSettingsModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                canCancel: true,
                props: {
                    officeSpaceData: OfficeSpace(this.$store.state.hybRoomsClient, this.viewTenantId, crypto.randomUUID(), "", false, 0),
                    title: this.$t('createOfficeSpace'),
                    canCancel: true
                },
                events: {
                    'submitNewOfficeSpace': (officeSpace) => {
                        const loadingComponentId = this.$showLoading(this.$t("creatingOfficeSpace"), this.$t("sentences.youWillBeAutomaticallyTakenToTheNewOfficeSpace"));
                        setTimeout(() => this.$closeLoading(loadingComponentId), 10 * 1000);
                        hybRoomsConnector.createOfficeSpace(officeSpace)
                            .then(response => { this.$store.state.officeSpaces.push(response.data); return response.data })
                            .then(officeSpace => this.navigateToOfficeSpace(officeSpace))
                            .finally(() => this.$closeLoading(loadingComponentId));
                    }
                }
            })
        },

        navigateToOfficeSpace(officeSpace) {
            const path = `/office/${officeSpace.tid}/${officeSpace.id}`;
            this.$router.push(path).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },

        openCreateRoomModal(adHoc = false) {
            this.$buefy.modal.open({
                parent: this,
                component: RoomSettingsModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                canCancel: true,
                props: {
                    roomData: Room(this.$store.state.hybRoomsClient, this.viewTenantId, this.viewedOfficeSpaceId, adHoc),
                    roomIndex: -1,
                    title: adHoc ? this.$t('quickCall') : this.$t('createRoom')
                },
                events: {
                    'submitNewRoom': (room) => {
                        if (adHoc && this.$store.state.demoMode.mockOnlineMeeting.active) {
                            this.$refs.router.$refs.officeSpaceView.__vue__.joinRoom({
                                tid: this.viewTenantId,
                                officeSpaceId: this.viewedOfficeSpaceId,
                                id: "5555"
                            });
                            return;
                        }
                        const loadingComponentId = this.$showLoading(this.$t("creatingRoom"), this.$store.state.demoMode.active ? "" : this.$t("waitingForMicrosoftServers"), 0.15);
                        setTimeout(() => this.$closeLoading(loadingComponentId), 10 * 1000);
                        hybRoomsConnector.createRoom(room)
                            .then(response => {
                                // this.$store.state.rooms.push(response.data);
                                if (adHoc) {
                                    this.$refs.router.$refs.officeSpaceView.__vue__.joinRoom(response.data);
                                }
                            })
                            .finally(() => this.$closeLoading(loadingComponentId));
                    }
                }
            })
        },

        createAdHocMeeting() {
            this.openCreateRoomModal(true);
        }
    }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('@/assets/generated/style.css') screen;
/*@import url('@/assets/generated/style_dark.css') screen and (prefers-color-scheme: dark);*/

/*@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap');
font-family: 'Plus Jakarta Sans', sans-serif;*/

#app {
    font-family: Comfortaa, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav a {
    font-weight: bold;
}

#nav a.router-link-exact-active {
    color: #1c79e4;
}

/* hide scrollbar */
element {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}
</style>